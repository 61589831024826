<script lang="ts">
  export let permit: Permit;
  let permitDuration;
  $: if (permit.counted.duration) {
    permitDuration = Temporal.Duration.from(permit.counted.duration)
      .total({
        unit: "hours",
        relativeTo: permit.valid.min.datetime,
      })
      .toLocaleString("en-US", { maximumFractionDigits: 2 });
  }
</script>

{#if permit && !permit.cancelled && permit.policy}
  <dl>
    <dt>Counts For</dt>
    <dd>
      {#if permit.exempt}
        No time (credited)
      {:else if permitDuration}
        <time datetime={permit.counted.interval}>
          {permitDuration} hours
        </time>
      {:else}
        Counted
      {/if}
    </dd>
  </dl>
{/if}
