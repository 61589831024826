<script lang="ts">
  import Record from "$components/record/RecordItem.svelte";
  import identity from "lodash-es/identity";
  import Time from "./Time.svelte";

  export let item: any = null;

  //$: logger("item=", item);

  $: subjects =
    (item &&
      [item.vehicle, item.space, item.media, item.tenant].filter(identity)) ||
    [];
</script>

<article class="{item.type} details" data-id={item.id}>
  <header>
    <h1>
      <data class={item.type} value={item.id} class:auth={item.invalid}
        ><dfn>Blocked</dfn>
      </data>

      <!-- <data class="{item.policy.type}" value="{item.policy.id}">{item.policy.title}</data> -->
    </h1>
  </header>

  <!-- <time datetime={item.recorded}
    ><abbr
      >{format(
        toZonedTime(item.recorded, item.timezone),
        "h:mm a MMM d yyyy zzz",
        {
          timeZone: item.timezone,
        }
      )}</abbr
    > -->

  <!-- {#if item.policy}
<p>
Blocked <data value="{item.policy.id}"><dfn>{item.policy.issue.title}</dfn> for {item.policy.title}</data>
</p>
{/if} -->
  <dl>
    <dt>Policy</dt>
    <dd>
      <data class={item.policy.type} value={item.policy.id}
        >{item.policy.title}</data
      >
    </dd>
    <dt>For</dt>
    <dd>
      <ul class="subjects">
        {#each subjects as item}<li><Record {item} /></li>{/each}
      </ul>
    </dd>
    <dt>Recorded</dt>
    <dd><Time datetime={item.recorded} timezone={item.timezone} /></dd>
    <dd><data class="user" value="system">Smart Guard</data></dd>
    {#if item.assigned}
      <dt>Reason</dt>
      {#if item.description}
        <dd>{item.description}</dd>
        <!-- {:else}
        <dd>Has existing permit</dd> -->
      {/if}
      <!-- <dd>Has existing permit</dd> -->
      <dd>
        Existing permit: <data value={item.assigned.id}
          >{item.assigned.title}</data
        >
      </dd>
    {/if}
    {#if item.meter}
      <dt>Reason</dt>
      <dd>Would have exceeded limit</dd>
      <dt>Limit</dt>
      <dd>
        <data value={item.meter.id}>
          <!-- <dfn>Would have exceeded </dfn> -->
          {item.meter.display}
          {#if item.meter.group}
            {#each subjects.filter((i) => i.type == item.meter.group) as item}
              for the {item.format || item.type}
            {/each}
          {:else}
            for the property
          {/if}
        </data>
      </dd>
      {#if item.quota?.attempted}
        <dt>Starting</dt>
        <dd>
          <Time
            datetime={item.quota.attempted.split("/")[0]}
            timezone={item.timezone}
            format={"h:mm a EEE MMM d yyyy zzz"}
          />
        </dd>
        <dt>Ending</dt>
        <dd>
          <Time
            datetime={item.quota.attempted.split("/")[1]}
            timezone={item.timezone}
            format={"h:mm a EEE MMM d yyyy zzz"}
          />
        </dd>
        <!-- <dd>Start
          <TimeInterval
            exact={true}
            interval={item.quota.attempted}
            timezone={item.timezone}
          />
        </dd> -->
      {/if}
    {/if}
    {#if item.donotpermit}
      <dt>Reason</dt>
      <dd><data value={item.donotpermit}><dfn>Banned</dfn></data></dd>
    {/if}

    {#if item.invalid}
      <dt>Reason</dt>
      <dd>Wrong passcode entered</dd>
      <dt>Entered</dt>
      <dd>{item.invalid}</dd>
    {/if}
    {#if item.violation}
      <dt>Reason</dt>
      <dd><data value={item.violation}><dfn>Violation</dfn></data></dd>
    {/if}
  </dl>
</article>
