import { map } from "lodash-es";

const remotelock = {
    id: "d63pcpnkd13v3499v2jyxv9aq4",
    name: "RemoteLock",
    service: "remotelock",
    type: "service",
    entry: true,
    format: "Access Control",
    //description:
    //"Add integrated controlled access to your amenity spaces for better self-service and security.",
    enabled: true,
    version: 1,
    approved: true,
    oauth: true,
};

const services: Record<string, Service> = {
    flock: {
        id: "flock",
        enabled: true,
        name: "Flock Safety",
        service: "flock",
        type: "service",
        pms: false,
        lpr: true,
        format: "License Plate Recognition",
        description: "",
        //enabled: true,
        //approved: true,
        development: "In Development",
        //suite: true,
    },
    insight: {
        id: "insight",
        enabled: false,
        name: "Insight",
        service: "insight",
        type: "service",
        lpr: true,
        pms: false,
        format: "License Plate Recognition",
        description: "",
        //enabled: true,
        //approved: true,
        //development: "In Development",
    },
    survision: {
        id: "survision",
        name: "Survision",
        service: "survision",
        type: "service",
        lpr: true,
        pms: false,
        format: "License Plate Recognition",
        description: "",
        enabled: true,
        //approved: true,
        //development: "In Development",
    },
    gatewise: {
        id: "gatewise",
        name: "Gatewise",
        service: "gatewise",
        type: "service",
        entry: true,
        format: "Access Control",
        //description: "Automatically grant entry to parking gates and doors based on parking ",
        enabled: true,
        version: 1,
        approved: true,
    },
    openvia: {
        id: "openvia",
        name: "OpenVia",
        service: "openvia",
        type: "service",
        entry: true,
        format: "Access Control",
        //description:
        //"Add integrated controlled access to your amenity spaces for better self-service and security.",
        enabled: true,
        version: 1,
        approved: true,
        //new: true,
    },
    //[remotelock.id]: remotelock,
    [remotelock.service]: remotelock,
    brivo: {
        id: "brivo",
        name: "Brivo Access",
        service: "brivo",
        type: "service",
        entry: true,
        format: "Access Control",
        description: "",
        version: 1,
        oauth: true,
        enabled: true,
        //development: "Early 2023",
        //new: true,
    },
    stripe: {
        id: "stripe",
        name: "Stripe Connect",
        service: "stripe",
        type: "service",
        payment: true,
        format: "Payments",
        description: "",
        enabled: true,
        version: 1,
        approved: true,
        oauth: true,
    },
    entrata: {
        id: "entrata",
        name: "Entrata Management Suite",
        service: "entrata",
        type: "service",
        pms: true,
        format: "Property Management",
        description: "",
        version: 1,
        enabled: true,
        // development: true,
        // beta: true,
        approved: true,
        suite: true,
    },
    rpode: {
        id: "rpode",
        name: "RealPage OneSite (ODE)",
        service: "realpage",
        type: "service",
        pms: true,
        format: "Property Management",
        description: "",
        version: 1,
        enabled: true,
        //development: true,
        //beta: true,
        approved: true,
        suite: true,
    },
    rpx: {
        id: "rpx",
        name: "RealPage OneSite (Exchange)",
        service: "realpage",
        type: "service",
        pms: true,
        enabled: false,
        format: "Property Management",
        description: "",
        development: "In Development",
        suite: true,
    },
    yardi: {
        id: "yardi",
        name: "Yardi Voyager",
        service: "yardi",
        type: "service",
        pms: true,
        format: "Property Management",
        description: "",
        enabled: true,
        approved: true,
        //development: "In Development",
        suite: true,
    },
}
export const list = Object.values(services);
export const directory = list.reduce((map, service) => {
    map.set(service.id, service);
    map.set(service.service, service);
    return map;
}, new Map<string, Service>());