<script lang="ts" module>
  var scan = param("scan");
</script>

<script lang="ts">
  import MediaScanner from "$components/media/MediaScanner.svelte";
  import { param, params } from "$utils/params";
  import router from "$utils/router";
  import { event } from "$utils/track";
  import { type Snippet, tick } from "svelte";

  let { property = null, children = null } = $props<{
    property?: Property | null;
    children?: Snippet;
  }>();

  let mode = $derived(typeof $scan == "string" ? $scan || "media" : "media");

  function onmedia(value: Media) {
    //const value = e.detail;

    if (!value?.type) return;

    blur();

    event("scanned", "Scanner", value.type);

    return router(
      `/properties/${property.id}/${value.type}/${value.id || value.key}`
    );

    // if (e.detail && e.detail.type == "vehicle") {
    //   let vehicle = e.detail;
    //   blur();
    //   return router(
    //     `/properties/${property.id}/${vehicle.type}/${vehicle.key}`
    //   );
    // }

    // if (e.detail && e.detail.type == "media") {
    //   let media = e.detail;
    //   logger("have media=", media);
    //   blur();
    //   return router(`/properties/${property.id}/media/${media.id}`);
    // }
  }

  // async function setScanMode(value) {
  //   //logger("setting scan mode = ", mode);
  //   await tick();
  //   params.update(($values) => {
  //     $values["scan"] = value;
  //     return $values;
  //   });
  // }
</script>

{#if property}
  {#if mode == "vehicle"}{:else if mode == "media"}
    <!-- <InfoTagScanner on:infotag={submit} /> -->
    <MediaScanner {onmedia} />
    <!-- <button
        type="button"
        value="vehicle"
        on:click={(e) => setScanMode("vehicle")}
        >Switch to scan Plate/Tag</button
      > -->
  {/if}
{/if}
