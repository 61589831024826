<script lang="ts">
  import { toZonedTime, format } from "date-fns-tz";
  import { toDays, toHours } from "duration-fns";
  import lowerCase from "lodash-es/lowerCase";

  let { item } = $props<{ item: any }>();
  logger("subjectpolicyusageitem=", item);

  function hours(duration: Temporal.Duration | Temporal.DurationLike | string) {
    const d = Temporal.Duration.from(duration);
    const total = d.total("minutes");
    const minutes = total % 60;
    const hours = (total - minutes) / 60;

    if (minutes === 0) return `${hours} hours`;
    return `${hours}:${minutes.toString().padStart(2, "0")} hours`;
  }
</script>

<article class="{item.type} details">
  <header>
    <h1>
      <data value={item.policy.id}>
        <!-- <dfn>{item.policy.issue.title}</dfn> for  -->
        {item.policy.title}
      </data>
    </h1>
  </header>
  {#if !item.enabled}
    <p>Does not request {item.subject.format || item.subject.type}</p>
  {:else if !item.allowed}
    <p>This {item.subject.format || item.subject.type} is blocked from using</p>
  {:else if item.donotpermit}
    <p>
      This {item.subject.format || item.subject.type} is
      <data value={item.donotpermit}>banned</data>
    </p>
  {:else if item.continuous}
    <p>
      This {item.subject.format || item.subject.type} is blocked because it has an
      <data value={item.continuous}>assigned permit</data>
    </p>
  {:else if !item.metered}
    <p>
      There are no specific limits on this {lowerCase(
        item.subject.format || item.subject.type
      )}, though any other limits still apply
    </p>
  {:else if item.metered}
    <ul>
      {#each Object.values(item.meters || {}) as item}
        <li>
          <dl>
            <dt>Limit</dt>
            <dd>{item.meter.display}</dd>
            {#each Object.values(item.used).slice(0, 1) as used}
              <dt>Used</dt>
              <dd>
                <time datetime={used.interval}>{used.display}</time>
              </dd>
            {/each}

            {#if item.meter.time}
              <dt>Counts</dt>
              <dd>Past & Future</dd>
              <dt>Resets</dt>
              {#if item.meter.calendar}
                <dd>
                  <time datetime={item.meter.interval.split("/")[1]}
                    >{format(
                      toZonedTime(
                        item.meter.interval.split("/")[1],
                        item.meter.timezone
                      ),
                      "h:mm a EEE MMM d yyyy zzz",
                      {
                        timeZone: item.meter.timezone,
                      }
                    )}</time
                  >
                </dd>
              {:else}
                <dd>
                  <time
                    >{toHours(item.meter.per) <= 72
                      ? toHours(item.meter.per) + " hours"
                      : toDays(item.meter.per) + " days"} after use</time
                  >
                </dd>
              {/if}
            {/if}
          </dl>
        </li>
      {/each}
    </ul>
  {/if}
  <!-- {#if item.enabled && null != (item.policy[item.subject.type] || item.policy[item.subject.format])?.fineblock}
    <dl>
      <dt>Fines</dt>
      <dd>
        {true ==
        (item.policy[item.subject.type] || item.policy[item.subject.format])
          ?.fineblock
          ? `block if any unpaid`
          : "ignore"}
      </dd>
    </dl>
  {/if} -->
</article>
