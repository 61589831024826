<script lang="ts">
  import { type Snippet } from "svelte";
  import InfoTagScanner from "$components/infotag/InfoTagScanner.svelte";
  import { event } from "$utils/track";
  import type { Infotag } from "$utils/infotag";

  let { onmedia = null, children = null } = $props<{
    children?: Snippet;
    onmedia?: (value: Media) => void;
  }>();

  //export let selected = null;

  // const dispatch = createEventDispatcher<{
  //   media: Media;
  // }>();

  function select(value: Media) {
    //logger("select=", value);

    //selected = value;
    event("mediascanned", "MediaScanner", `${value.type},${value.id}`);
    if (value) onmedia?.(value);
  }

  // async function onbarcode(e) {
  //   const url = e.detail;

  //   logger("url=", url);

  //   const info = infotag.parse(url);
  //   if (!info || "media" != info.format) return;

  //   // convert to a media object
  //   const value = {
  //     type: info.format,
  //     id: info.id,
  //     key: info.serial,
  //     url: url,
  //   };
  //   return select(value);
  // }

  async function oninfotag(infotag: Infotag) {
    //const infotag = e.detail;

    if (!infotag || "media" != infotag?.format) return;

    const value = {
      type: infotag.format,
      id: infotag.id,
      key: infotag.serial,
      url: infotag.url,
    } as Media;

    if (!value || "media" != value?.type) return;
    return select(value);
  }
</script>

<InfoTagScanner {oninfotag}>
  {@render children?.()}
</InfoTagScanner>
