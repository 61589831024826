<script lang="ts">
  import { type Snippet } from "svelte";
  import { infotag, type Infotag } from "$utils/infotag";
  import UrlBarcodeScanner from "./UrlBarcodeScanner.svelte";
  import { event } from "$utils/track";

  //export let selected = null;
  //export let property = null; // property gates observation flag?

  let {
    //property = null,
    children = null,
    oninfotag = null,
  } = $props<{
    property?: string | null;
    children?: Snippet;
    oninfotag?: (value: Infotag) => void;
  }>();

  function select(value: Infotag) {
    //logger("infotag scanner value=", value);

    //selected = value;
    event(
      "infoscanned",
      "InfoTagScanner",
      value.url ??
        `${value.type}.${value.version},${value.ref},${value.id},${value.serial}`
    );
    if (value) oninfotag?.(value);
  }

  function onurl(url?: URL) {
    //const url = e.detail;

    //logger("url=", url);
    if (!url) return;

    const info = infotag.parse(url);
    logger("infotag=", info);
    if (!info) return;

    //info.url = url.href;
    return select(info);
  }
</script>

<UrlBarcodeScanner {onurl}>{@render children?.()}</UrlBarcodeScanner>
