<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import {
    getSvelteContext,
    type MapGeoJSONFeature,
    type MapLayerMouseEvent,
    type MapMouseEvent,
  } from ".";

  import type { VectorGLMap } from ".";
  const eventing = createEventDispatcher();

  const { styledmap } = getSvelteContext();
  let MAP: VectorGLMap;

  export let filter:
    | any[]
    | ((feature: MapGeoJSONFeature | GeoJSON.Feature) => Boolean) = null;
  export let level: string | null | undefined;
  let layer: string | null = null;

  function click(features: MapGeoJSONFeature[]) {
    // if level is set and there's multiple features on different levels, filter down to just the selected level
    if (
      features.length > 1 &&
      null != level &&
      "" != level &&
      new Set<string>(features.map((feature) => feature.properties.level))
        .size > 1
    ) {
      features = features.filter(
        (feature) => feature.properties.level == level
      );
    }
    for (const feature of features) {
      eventing("click", feature);
    }
  }

  function onclick(e: MapMouseEvent) {
    logger("mapfeatureclick.click=", e);
    const features = e.target
      .queryRenderedFeatures(
        e.point,
        filter instanceof Function
          ? undefined
          : {
              filter,
            }
      )
      .filter(filter instanceof Function ? filter : () => true);
    logger("mapfeatureclick.click=", e, features, filter);

    click(features);
  }

  function init($map: VectorGLMap, $layer?: string) {
    cleanup($layer); // cleanup previous map
    MAP = $map; // set

    // events
    if ($layer) {
      MAP?.on("click", $layer, onclick);
    } else {
      MAP?.on("click", onclick);
    }
  }
  function cleanup($layer?: string) {
    if (!MAP) return; // no map to cleanup

    // events
    if ($layer) MAP?.off("click", $layer, onclick);
    MAP?.off("click", onclick);

    MAP = null; //set
  }

  $: init($styledmap, layer);

  onMount(function () {
    //const unsubscribe = map.subscribe(init);
    return function destroy() {
      cleanup(layer);
      //unsubscribe();
    };
  });
</script>

<slot />
