<script lang="ts">
  import Camera from "$components/camera/Camera.svelte";
  import { event } from "$utils/track";

  import { onMount, type Snippet } from "svelte";

  let { onurl = null, children = null } = $props<{
    onurl?: (url: URL) => void;
    children?: Snippet;
  }>();

  let preview: string | null | undefined = $state(null);
  let last: string | null | undefined = $state(null);

  onMount(() => {
    return clearPreview;
  });

  function clearPreview() {
    setPreview(null);
  }

  function setPreview(fileOrBlob?: Blob | File | null) {
    if (preview) {
      URL.revokeObjectURL(preview);
    }
    preview = fileOrBlob && URL.createObjectURL(fileOrBlob);
    //logger("preview=", preview);
  }

  function select(value: URL) {
    if (!value) return;
    event("urlscanned", "URLBarcodeScanner", value.toString());
    if (value) onurl?.(value);
    clearPreview(); // done
  }

  function onbarcode(barcode: string, blob?: Blob | null) {
    // If we're already processing, don't process more than one at a time.
    if (preview) return;

    logger("onbarcode=", barcode, blob);

    //const { barcode, blob } = e.detail;

    const photo = blob;

    if (!photo || !barcode || barcode == last) clearPreview();

    if (!barcode) return;
    if (barcode === last) return; // no new barcode
    let url: URL;

    //if (URL.canParse && !URL.canParse(barcode)) return;
    if (typeof URL.canParse === "function") {
      if (!URL.canParse(barcode)) return;
      url = new URL(barcode);
    } else {
      try {
        url = new URL(barcode);
      } catch {
        // not a url
        return clearPreview();
      }
    }

    //logger("onbarcode=", barcode, blob);

    // try {
    //   url = new URL(barcode);
    // } catch {
    //   // not a url
    //   return clearPreview();
    // }

    setPreview(photo);

    last = barcode;

    select(url);
  }
</script>

<nav class="scan">
  <Camera
    class="smart-decal"
    barcode={true}
    cover={preview}
    upload={false}
    {onbarcode}
  />
  {@render children?.()}
</nav>
